import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "navigation" }
const _hoisted_2 = { class: "navigation__menu" }
const _hoisted_3 = { class: "icon" }
const _hoisted_4 = { class: "icon" }
const _hoisted_5 = { class: "icon" }
const _hoisted_6 = { class: "icon" }
const _hoisted_7 = { class: "icon" }
const _hoisted_8 = { class: "icon" }
const _hoisted_9 = { class: "icon" }
const _hoisted_10 = { class: "icon" }
const _hoisted_11 = { class: "icon" }
const _hoisted_12 = { class: "icon" }
const _hoisted_13 = { class: "icon" }
const _hoisted_14 = { class: "icon" }
const _hoisted_15 = { class: "icon" }
const _hoisted_16 = { class: "icon" }
const _hoisted_17 = { class: "icon" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_House = _resolveComponent("House")!
  const _component_el_menu_item = _resolveComponent("el-menu-item")!
  const _component_ShoppingBag = _resolveComponent("ShoppingBag")!
  const _component_el_sub_menu = _resolveComponent("el-sub-menu")!
  const _component_CircleCheck = _resolveComponent("CircleCheck")!
  const _component_VideoPlay = _resolveComponent("VideoPlay")!
  const _component_el_menu = _resolveComponent("el-menu")!
  const _component_Setting = _resolveComponent("Setting")!
  const _component_User = _resolveComponent("User")!
  const _component_Message = _resolveComponent("Message")!
  const _component_Aim = _resolveComponent("Aim")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.isUserAdministrator)
        ? (_openBlock(), _createBlock(_component_el_menu, {
            key: 0,
            onSelect: _ctx.setRoute
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_menu_item, { index: "AdministrationAdministratorDashboardIndex" }, {
                title: _withCtx(() => [
                  _createElementVNode("span", _hoisted_3, [
                    _createVNode(_component_House)
                  ]),
                  _cache[0] || (_cache[0] = _createElementVNode("span", { class: "label" }, " Dashboard ", -1))
                ]),
                _: 1
              }),
              _createVNode(_component_el_sub_menu, { index: "AdministrationAdministratorShopIndex" }, {
                title: _withCtx(() => [
                  _createElementVNode("span", _hoisted_4, [
                    _createVNode(_component_ShoppingBag)
                  ]),
                  _cache[1] || (_cache[1] = _createElementVNode("span", { class: "label" }, " Shop ", -1))
                ]),
                default: _withCtx(() => [
                  _createVNode(_component_el_menu_item, { index: "AdministrationAdministratorShopOrdersIndex" }, {
                    title: _withCtx(() => _cache[2] || (_cache[2] = [
                      _createElementVNode("span", { class: "label" }, " Orders ", -1)
                    ])),
                    _: 1
                  }),
                  _createVNode(_component_el_menu_item, { index: "AdministrationAdministratorShopArtistsIndex" }, {
                    title: _withCtx(() => _cache[3] || (_cache[3] = [
                      _createElementVNode("span", { class: "label" }, " Artists ", -1)
                    ])),
                    _: 1
                  }),
                  _createVNode(_component_el_menu_item, { index: "AdministrationAdministratorShopEventsIndex" }, {
                    title: _withCtx(() => _cache[4] || (_cache[4] = [
                      _createElementVNode("span", { class: "label" }, " Events ", -1)
                    ])),
                    _: 1
                  }),
                  _createVNode(_component_el_menu_item, { index: "AdministrationAdministratorShopVideosOnDemandIndex" }, {
                    title: _withCtx(() => _cache[5] || (_cache[5] = [
                      _createElementVNode("span", { class: "label" }, " Videos on demand ", -1)
                    ])),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_el_sub_menu, { index: "AdministrationAdministratorApprovalsIndex" }, {
                title: _withCtx(() => [
                  _createElementVNode("span", _hoisted_5, [
                    _createVNode(_component_CircleCheck)
                  ]),
                  _cache[6] || (_cache[6] = _createElementVNode("span", { class: "label" }, " Approvals ", -1))
                ]),
                default: _withCtx(() => [
                  _createVNode(_component_el_menu_item, { index: "AdministrationAdministratorApprovalsArtistsIndex" }, {
                    title: _withCtx(() => _cache[7] || (_cache[7] = [
                      _createElementVNode("span", { class: "label" }, " Artists ", -1)
                    ])),
                    _: 1
                  }),
                  _createVNode(_component_el_menu_item, { index: "AdministrationAdministratorApprovalsEventsIndex" }, {
                    title: _withCtx(() => _cache[8] || (_cache[8] = [
                      _createElementVNode("span", { class: "label" }, " Events ", -1)
                    ])),
                    _: 1
                  }),
                  _createVNode(_component_el_menu_item, { index: "AdministrationAdministratorApprovalsVideosOnDemandIndex" }, {
                    title: _withCtx(() => _cache[9] || (_cache[9] = [
                      _createElementVNode("span", { class: "label" }, " Videos on demand ", -1)
                    ])),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_el_menu_item, { index: "AdministrationAdministratorVideosIndex" }, {
                title: _withCtx(() => [
                  _createElementVNode("span", _hoisted_6, [
                    _createVNode(_component_VideoPlay)
                  ]),
                  _cache[10] || (_cache[10] = _createElementVNode("span", { class: "label" }, " Videos ", -1))
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["onSelect"]))
        : _createCommentVNode("", true),
      (_ctx.isUserPartner)
        ? (_openBlock(), _createBlock(_component_el_menu, {
            key: 1,
            onSelect: _ctx.setRoute
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_menu_item, { index: "AdministrationPartnerDashboardIndex" }, {
                title: _withCtx(() => [
                  _createElementVNode("span", _hoisted_7, [
                    _createVNode(_component_House)
                  ]),
                  _cache[11] || (_cache[11] = _createElementVNode("span", { class: "label" }, " Dashboard ", -1))
                ]),
                _: 1
              }),
              _createVNode(_component_el_sub_menu, { index: "AdministrationPartnerShopIndex" }, {
                title: _withCtx(() => [
                  _createElementVNode("span", _hoisted_8, [
                    _createVNode(_component_ShoppingBag)
                  ]),
                  _cache[12] || (_cache[12] = _createElementVNode("span", { class: "label" }, " Shop ", -1))
                ]),
                default: _withCtx(() => [
                  _createVNode(_component_el_menu_item, { index: "AdministrationPartnerShopArtistsIndex" }, {
                    title: _withCtx(() => _cache[13] || (_cache[13] = [
                      _createElementVNode("span", { class: "label" }, " Artists ", -1)
                    ])),
                    _: 1
                  }),
                  _createVNode(_component_el_menu_item, { index: "AdministrationPartnerShopEventsIndex" }, {
                    title: _withCtx(() => _cache[14] || (_cache[14] = [
                      _createElementVNode("span", { class: "label" }, " Events ", -1)
                    ])),
                    _: 1
                  }),
                  _createVNode(_component_el_menu_item, { index: "AdministrationPartnerShopVideosOnDemandIndex" }, {
                    title: _withCtx(() => _cache[15] || (_cache[15] = [
                      _createElementVNode("span", { class: "label" }, " Videos on demand ", -1)
                    ])),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_el_sub_menu, { index: "AdministrationPartnerApprovalsIndex" }, {
                title: _withCtx(() => [
                  _createElementVNode("span", _hoisted_9, [
                    _createVNode(_component_CircleCheck)
                  ]),
                  _cache[16] || (_cache[16] = _createElementVNode("span", { class: "label" }, " Approvals ", -1))
                ]),
                default: _withCtx(() => [
                  _createVNode(_component_el_menu_item, { index: "AdministrationPartnerApprovalsArtistsIndex" }, {
                    title: _withCtx(() => _cache[17] || (_cache[17] = [
                      _createElementVNode("span", { class: "label" }, " Artists ", -1)
                    ])),
                    _: 1
                  }),
                  _createVNode(_component_el_menu_item, { index: "AdministrationPartnerApprovalsEventsIndex" }, {
                    title: _withCtx(() => _cache[18] || (_cache[18] = [
                      _createElementVNode("span", { class: "label" }, " Events ", -1)
                    ])),
                    _: 1
                  }),
                  _createVNode(_component_el_menu_item, { index: "AdministrationPartnerApprovalsVideosOnDemandIndex" }, {
                    title: _withCtx(() => _cache[19] || (_cache[19] = [
                      _createElementVNode("span", { class: "label" }, " Videos on demand ", -1)
                    ])),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["onSelect"]))
        : _createCommentVNode("", true),
      (_ctx.isUserSuperAdministrator)
        ? (_openBlock(), _createBlock(_component_el_menu, {
            key: 2,
            onSelect: _ctx.setRoute
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_menu_item, { index: "AdministrationSuperAdministratorDashboardIndex" }, {
                title: _withCtx(() => [
                  _createElementVNode("span", _hoisted_10, [
                    _createVNode(_component_House)
                  ]),
                  _cache[20] || (_cache[20] = _createElementVNode("span", { class: "label" }, " Dashboard ", -1))
                ]),
                _: 1
              }),
              _createVNode(_component_el_sub_menu, { index: "AdministrationSuperAdministratorShopIndex" }, {
                title: _withCtx(() => [
                  _createElementVNode("span", _hoisted_11, [
                    _createVNode(_component_ShoppingBag)
                  ]),
                  _cache[21] || (_cache[21] = _createElementVNode("span", { class: "label" }, " Shop ", -1))
                ]),
                default: _withCtx(() => [
                  _createVNode(_component_el_menu_item, { index: "AdministrationSuperAdministratorShopOrdersIndex" }, {
                    title: _withCtx(() => _cache[22] || (_cache[22] = [
                      _createElementVNode("span", { class: "label" }, " Orders ", -1)
                    ])),
                    _: 1
                  }),
                  _createVNode(_component_el_menu_item, { index: "AdministrationSuperAdministratorShopArtistsIndex" }, {
                    title: _withCtx(() => _cache[23] || (_cache[23] = [
                      _createElementVNode("span", { class: "label" }, " Artists ", -1)
                    ])),
                    _: 1
                  }),
                  _createVNode(_component_el_menu_item, { index: "AdministrationSuperAdministratorShopEventsIndex" }, {
                    title: _withCtx(() => _cache[24] || (_cache[24] = [
                      _createElementVNode("span", { class: "label" }, " Events ", -1)
                    ])),
                    _: 1
                  }),
                  _createVNode(_component_el_menu_item, { index: "AdministrationSuperAdministratorShopVideosOnDemandIndex" }, {
                    title: _withCtx(() => _cache[25] || (_cache[25] = [
                      _createElementVNode("span", { class: "label" }, " Videos on demand ", -1)
                    ])),
                    _: 1
                  }),
                  _createVNode(_component_el_menu_item, { index: "AdministrationSuperAdministratorShopCouponsIndex" }, {
                    title: _withCtx(() => _cache[26] || (_cache[26] = [
                      _createElementVNode("span", { class: "label" }, " Coupons ", -1)
                    ])),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_el_sub_menu, { index: "AdministrationSuperAdministratorApprovalsIndex" }, {
                title: _withCtx(() => [
                  _createElementVNode("span", _hoisted_12, [
                    _createVNode(_component_CircleCheck)
                  ]),
                  _cache[27] || (_cache[27] = _createElementVNode("span", { class: "label" }, " Approvals ", -1))
                ]),
                default: _withCtx(() => [
                  _createVNode(_component_el_menu_item, { index: "AdministrationSuperAdministratorApprovalsArtistsIndex" }, {
                    title: _withCtx(() => _cache[28] || (_cache[28] = [
                      _createElementVNode("span", { class: "label" }, " Artists ", -1)
                    ])),
                    _: 1
                  }),
                  _createVNode(_component_el_menu_item, { index: "AdministrationSuperAdministratorApprovalsEventsIndex" }, {
                    title: _withCtx(() => _cache[29] || (_cache[29] = [
                      _createElementVNode("span", { class: "label" }, " Events ", -1)
                    ])),
                    _: 1
                  }),
                  _createVNode(_component_el_menu_item, { index: "AdministrationSuperAdministratorApprovalsVideosOnDemandIndex" }, {
                    title: _withCtx(() => _cache[30] || (_cache[30] = [
                      _createElementVNode("span", { class: "label" }, " Videos on demand ", -1)
                    ])),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_el_sub_menu, { index: "AdministrationSuperAdministratorSettingsIndex" }, {
                title: _withCtx(() => [
                  _createElementVNode("span", _hoisted_13, [
                    _createVNode(_component_Setting)
                  ]),
                  _cache[31] || (_cache[31] = _createElementVNode("span", { class: "label" }, " Settings ", -1))
                ]),
                default: _withCtx(() => [
                  _createVNode(_component_el_menu_item, { index: "AdministrationSuperAdministratorSettingsCurrenciesIndex" }, {
                    title: _withCtx(() => _cache[32] || (_cache[32] = [
                      _createElementVNode("span", { class: "label" }, " Currencies ", -1)
                    ])),
                    _: 1
                  }),
                  _createVNode(_component_el_menu_item, { index: "AdministrationSuperAdministratorSettingsProductTypesIndex" }, {
                    title: _withCtx(() => _cache[33] || (_cache[33] = [
                      _createElementVNode("span", { class: "label" }, " Product Types ", -1)
                    ])),
                    _: 1
                  }),
                  _createVNode(_component_el_menu_item, { index: "AdministrationSuperAdministratorSettingsProductCategoriesIndex" }, {
                    title: _withCtx(() => _cache[34] || (_cache[34] = [
                      _createElementVNode("span", { class: "label" }, " Product Categories ", -1)
                    ])),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_el_menu_item, { index: "AdministrationSuperAdministratorUsersIndex" }, {
                title: _withCtx(() => [
                  _createElementVNode("span", _hoisted_14, [
                    _createVNode(_component_User)
                  ]),
                  _cache[35] || (_cache[35] = _createElementVNode("span", { class: "label" }, " Users ", -1))
                ]),
                _: 1
              }),
              _createVNode(_component_el_menu_item, { index: "AdministrationSuperAdministratorNewslettersIndex" }, {
                title: _withCtx(() => [
                  _createElementVNode("span", _hoisted_15, [
                    _createVNode(_component_Message)
                  ]),
                  _cache[36] || (_cache[36] = _createElementVNode("span", { class: "label" }, " Newsletters ", -1))
                ]),
                _: 1
              }),
              _createVNode(_component_el_menu_item, { index: "AdministrationSuperAdministratorVideosIndex" }, {
                title: _withCtx(() => [
                  _createElementVNode("span", _hoisted_16, [
                    _createVNode(_component_VideoPlay)
                  ]),
                  _cache[37] || (_cache[37] = _createElementVNode("span", { class: "label" }, " Videos ", -1))
                ]),
                _: 1
              }),
              _createVNode(_component_el_menu_item, { index: "AdministrationSuperAdministratorPromotionsIndex" }, {
                title: _withCtx(() => [
                  _createElementVNode("span", _hoisted_17, [
                    _createVNode(_component_Aim)
                  ]),
                  _cache[38] || (_cache[38] = _createElementVNode("span", { class: "label" }, " Promotions ", -1))
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["onSelect"]))
        : _createCommentVNode("", true)
    ])
  ]))
}